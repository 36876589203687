import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './Register.css';

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:5000';

const Register = () => {
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [passwordHint, setPasswordHint] = useState('');
    const [error, setError] = useState('');
    const [passwordValidations, setPasswordValidations] = useState({
        length: false,
        specialChar: false,
        uppercase: false,
        number: false,
    });
    const [isPasswordFocused, setIsPasswordFocused] = useState(false);
    const [isUsernameFocused, setIsUsernameFocused] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const length = password.length >= 8;
        const specialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);
        const uppercase = /[A-Z]/.test(password);
        const number = /[0-9]/.test(password);

        setPasswordValidations({ length, specialChar, uppercase, number });
    }, [password]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');

        if (username.length < 5) {
            setError('Username must be at least 5 characters long');
            return;
        }

        if (Object.values(passwordValidations).includes(false)) {
            setError('Password does not meet the required criteria');
            return;
        }

        if (password !== confirmPassword) {
            setError('Passwords do not match');
            return;
        }

        try {
            const apiUrl = `${API_URL}/api/auth/register`;
            console.log('API URL:', apiUrl);

            const res = await axios.post(apiUrl, { username, email, password, passwordHint });
            console.log('Response:', res);

            if (res && res.data && res.data.token) {
                localStorage.setItem('token', res.data.token);
                navigate('/');
            } else {
                setError('Unexpected response from server');
            }
        } catch (err) {
            console.error('Registration error:', err);
            if (err.response && err.response.data && err.response.data.errors) {
                setError(err.response.data.errors.map(e => e.msg).join(', '));
            } else if (err.response) {
                setError(`Error ${err.response.status}: ${err.response.statusText} | ${err.response.data.msg || err.response.data}`);
            } else {
                setError('An error occurred during registration. Please try again.');
            }
        }
    };

    return (
        <div className="register-background">
            <div className="register-form">
                <div className="text">REGISTER</div>
                <form onSubmit={handleSubmit}>
                    <div className="register-field">
                        <i className="fas fa-user icon"></i>
                        <input 
                            type="text" 
                            placeholder="Username (required)" 
                            value={username} 
                            onChange={(e) => setUsername(e.target.value)} 
                            required 
                            minLength={5}
                            pattern="^[a-zA-Z0-9]+$"
                            title="Username should be at least 5 characters long and contain only letters and numbers."
                            onFocus={() => setIsUsernameFocused(true)}
                            onBlur={() => setIsUsernameFocused(false)}
                        />
                    </div>
                    <div className={`username-requirements ${isUsernameFocused ? 'show' : ''}`}>
                        <p>
                            <span className={username.length >= 5 ? 'valid' : 'invalid'}>
                                {username.length >= 5 ? '\u2714' : '\u2716'}
                            </span> 
                            At least 5 characters
                        </p>
                    </div>
                    <div className="register-field">
                        <i className="fas fa-envelope icon"></i>
                        <input 
                            type="email" 
                            placeholder="Email (required)" 
                            value={email} 
                            onChange={(e) => setEmail(e.target.value)} 
                            required 
                        />
                    </div>
                    <div className="register-field">
                        <i className="fas fa-lock icon"></i>
                        <input 
                            type="password" 
                            placeholder="Password (required)" 
                            value={password} 
                            onChange={(e) => setPassword(e.target.value)} 
                            required 
                            minLength={8}
                            title="Password should be at least 8 characters long."
                            onFocus={() => setIsPasswordFocused(true)}
                            onBlur={() => setIsPasswordFocused(false)}
                        />
                    </div>
                    <div className="register-field">
                        <i className="fas fa-lock icon"></i>
                        <input 
                            type="password" 
                            placeholder="Confirm Password (required)" 
                            value={confirmPassword} 
                            onChange={(e) => setConfirmPassword(e.target.value)} 
                            required 
                            minLength={8}
                            title="Please confirm your password."
                        />
                    </div>
                    <div className="register-field">
                        <i className="fas fa-lightbulb icon"></i>
                        <input 
                            type="text" 
                            placeholder="Password Hint (optional)" 
                            value={passwordHint} 
                            onChange={(e) => setPasswordHint(e.target.value)} 
                        />
                    </div>
                    <div className={`password-requirements ${isPasswordFocused ? 'show' : ''}`}>
                        <p>Password must contain:</p>
                        <p>
                            <span className={passwordValidations.length ? 'valid' : 'invalid'}>
                                {passwordValidations.length ? '\u2714' : '\u2716'}
                            </span> 
                            At least 8 characters
                        </p>
                        <p>
                            <span className={passwordValidations.specialChar ? 'valid' : 'invalid'}>
                                {passwordValidations.specialChar ? '\u2714' : '\u2716'}
                            </span> 
                            At least one special character (!, @, etc.)
                        </p>
                        <p>
                            <span className={passwordValidations.uppercase ? 'valid' : 'invalid'}>
                                {passwordValidations.uppercase ? '\u2714' : '\u2716'}
                            </span> 
                            At least one uppercase letter
                        </p>
                        <p>
                            <span className={passwordValidations.number ? 'valid' : 'invalid'}>
                                {passwordValidations.number ? '\u2714' : '\u2716'}
                            </span> 
                            At least one number
                        </p>
                    </div>
                    <button type="submit">Register</button>
                </form>
                {error && <p>{error}</p>}
            </div>
        </div>
    );
};

export default Register;
