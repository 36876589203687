import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, Link } from 'react-router-dom';
import './Login.css';
import '@fortawesome/fontawesome-free/css/all.min.css'; // Import Font Awesome CSS

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:5000';
console.log('API_URL:', API_URL); // Add this line to log the API_URL for debugging

const Login = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');

        if (!username || !password) {
            setError('Username and password are required');
            return;
        }

        try {
            const res = await axios.post(`${API_URL}/api/auth/login`, { username, password });
            console.log('Response:', res);

            if (res && res.data && res.data.token) {
                localStorage.setItem('token', res.data.token);
                // Set token expiry in localStorage
                localStorage.setItem('tokenExpiry', new Date().getTime() + 30 * 60 * 1000); // 30 minutes

                navigate('/');
            } else {
                setError('Unexpected response from server');
            }
        } catch (err) {
            console.error('Login error:', err);
            if (err.response) {
                setError(`Error ${err.response.status}: ${err.response.statusText} | ${err.response.data.msg || err.response.data}`);
            } else if (err.request) {
                setError('Failed to connect to the server. Please try again later.');
            } else {
                setError('An error occurred during login. Please try again.');
            }
        }
    };

    // Check token expiry on component mount
    useEffect(() => {
        const tokenExpiry = localStorage.getItem('tokenExpiry');
        if (tokenExpiry && new Date().getTime() > tokenExpiry) {
            localStorage.removeItem('token');
            localStorage.removeItem('tokenExpiry');
            navigate('/login');
        }
    }, [navigate]);

    return (
        <div className="login-background">
            <div className="login-form">
                <div className="text">LOGIN</div>
                <form onSubmit={handleSubmit}>
                    <div className="login-field">
                        <i className="fas fa-user icon"></i>
                        <input 
                            type="text" 
                            placeholder="Username" 
                            value={username} 
                            onChange={(e) => setUsername(e.target.value)} 
                            required 
                        />
                    </div>
                    <div className="login-field">
                        <i className="fas fa-lock icon"></i>
                        <input 
                            type="password" 
                            placeholder="Password" 
                            value={password} 
                            onChange={(e) => setPassword(e.target.value)} 
                            required 
                        />
                    </div>
                    <button type="submit">Login</button>
                </form>
                {error && <p>{error}</p>}
                <p>
                    <Link to="/reset-password">Forgot your password?</Link>
                </p>
            </div>
        </div>
    );
};

export default Login;
