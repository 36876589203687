import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './Home.css';

const Home = () => {
    const [userId, setUserId] = useState('');
    const [reason, setReason] = useState('');
    const [message, setMessage] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (!token) {
            navigate('/login');
        }
    }, [navigate]);

    const handleLogout = () => {
        localStorage.removeItem('token');
        navigate('/login');
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setMessage(''); // Clear any previous messages

        const token = localStorage.getItem('token');
        console.log('Submitting userId:', userId); // Add logging for debugging
        console.log('Submitting reason:', reason); // Add logging for debugging

        try {
            const response = await fetch('http://localhost:5000/api/blacklist', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`, // Include token in the headers
                },
                body: JSON.stringify({ userId, reason }),
            });

            if (!response.ok) {
                const errorText = await response.text();
                console.error('Error response:', errorText);
                throw new Error(`Error ${response.status}: ${response.statusText} | ${JSON.parse(errorText).msg}`);
            }

            const result = await response.json();
            console.log('Response:', result);

            setMessage('User successfully blacklisted.');
        } catch (error) {
            console.error('Fetch error:', error);
            setMessage(`Error: ${error.message}`);
        }
    };

    return (
        <div className="home-container">
            <h2>Welcome to the Home Page</h2>
            <p>You are logged in. Feel free to explore!</p>
            <button onClick={handleLogout}>Logout</button>
            <form onSubmit={handleSubmit}>
                <label>
                    User ID to Blacklist:
                    <input
                        type="text"
                        value={userId}
                        onChange={(e) => setUserId(e.target.value)}
                        required
                    />
                </label>
                <label>
                    Reason for Blacklisting:
                    <input
                        type="text"
                        value={reason}
                        onChange={(e) => setReason(e.target.value)}
                        required
                    />
                </label>
                <button type="submit">Blacklist User</button>
            </form>
            {message && <p>{message}</p>}
        </div>
    );
};

export default Home;
