import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './ResetPassword.css';

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:5000';

const ResetPassword = () => {
    const [username, setUsername] = useState('');
    const [passwordHint, setPasswordHint] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState('');
    const [passwordValidations, setPasswordValidations] = useState({
        length: false,
        specialChar: false,
        uppercase: false,
        number: false,
    });
    const [isPasswordFocused, setIsPasswordFocused] = useState(false);
    const [success, setSuccess] = useState('');
    const navigate = useNavigate();

    const validatePassword = (password) => {
        const length = password.length >= 8;
        const specialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);
        const uppercase = /[A-Z]/.test(password);
        const number = /[0-9]/.test(password);

        setPasswordValidations({ length, specialChar, uppercase, number });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');
        setSuccess('');

        if (newPassword !== confirmPassword) {
            setError('Passwords do not match');
            return;
        }

        if (Object.values(passwordValidations).includes(false)) {
            setError('Password does not meet the required criteria');
            return;
        }

        try {
            const res = await axios.post(`${API_URL}/api/auth/reset-password`, { username, passwordHint, newPassword });
            setSuccess('Password reset successfully. You can now login.');
            setTimeout(() => navigate('/login'), 3000);
        } catch (err) {
            console.error('Reset password error:', err);
            if (err.response) {
                setError(`Error ${err.response.status}: ${err.response.statusText} | ${err.response.data.msg || err.response.data}`);
            } else {
                setError('Error resetting password. Please check your username and password hint.');
            }
        }
    };

    return (
        <div className="reset-password-background">
            <div className="reset-password-form">
                <div className="text">RESET PASSWORD</div>
                <form onSubmit={handleSubmit}>
                    <div className="resetpassword-field">
                        <i className="fas fa-user icon"></i>
                        <input 
                            type="text" 
                            placeholder="Username" 
                            value={username} 
                            onChange={(e) => setUsername(e.target.value)} 
                            required 
                        />
                    </div>
                    <div className="resetpassword-field">
                        <i className="fas fa-lightbulb icon"></i>
                        <input 
                            type="text" 
                            placeholder="Password Hint" 
                            value={passwordHint} 
                            onChange={(e) => setPasswordHint(e.target.value)} 
                            required 
                        />
                    </div>
                    <div className="resetpassword-field">
                        <i className="fas fa-lock icon"></i>
                        <input 
                            type="password" 
                            placeholder="New Password" 
                            value={newPassword} 
                            onChange={(e) => {
                                setNewPassword(e.target.value);
                                validatePassword(e.target.value);
                            }} 
                            required 
                            minLength={8}
                            onFocus={() => setIsPasswordFocused(true)}
                            onBlur={() => setIsPasswordFocused(false)}
                        />
                    </div>
                    <div className="resetpassword-field">
                        <i className="fas fa-lock icon"></i>
                        <input 
                            type="password" 
                            placeholder="Confirm New Password" 
                            value={confirmPassword} 
                            onChange={(e) => setConfirmPassword(e.target.value)} 
                            required 
                            minLength={8}
                        />
                    </div>
                    <div className={`password-requirements ${isPasswordFocused ? 'show' : ''}`}>
                        <p>Password must contain:</p>
                        <p>
                            <span className={passwordValidations.length ? 'valid' : 'invalid'}>
                                {passwordValidations.length ? '\u2714' : '\u2716'}
                            </span> 
                            At least 8 characters
                        </p>
                        <p>
                            <span className={passwordValidations.specialChar ? 'valid' : 'invalid'}>
                                {passwordValidations.specialChar ? '\u2714' : '\u2716'}
                            </span> 
                            At least one special character (!, @, etc.)
                        </p>
                        <p>
                            <span className={passwordValidations.uppercase ? 'valid' : 'invalid'}>
                                {passwordValidations.uppercase ? '\u2714' : '\u2716'}
                            </span> 
                            At least one uppercase letter
                        </p>
                        <p>
                            <span className={passwordValidations.number ? 'valid' : 'invalid'}>
                                {passwordValidations.number ? '\u2714' : '\u2716'}
                            </span> 
                            At least one number
                        </p>
                    </div>
                    <button type="submit">Reset Password</button>
                </form>
                {error && <p>{error}</p>}
                {success && <p>{success}</p>}
            </div>
        </div>
    );
};

export default ResetPassword;
